import permissions from '@permissions/permissions.json';

export const AdminMenu = {
    items: [
        {
            label: 'Agents',
            icon: 'pi pi-fw icon-agents icon-size-sm',
            routerLink: ['/agents'],
            permissions: permissions.AgentPermissions.ViewAgent
        },
        {
            label: 'Players',
            icon: 'pi pi-fw icon-players icon-size-sm', routerLink: ['/players'],
            permissions: permissions.PlayerPermissions.ViewPlayer
        },
        {
            label: 'Transactions',
            icon: 'pi pi-fw icon-transaction icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'AgentTransactions',
                    routerLink: ['/agent-transactions', 'transfers'],
                    permissions: permissions.TransactionsPermissions.ViewAgentTransactions
                },
                {
                    label: 'PlayerTransactions',
                    routerLink: ['/player-requests', 'transfers'],
                    permissions: permissions.TransactionsPermissions.ViewPlayerTransactions
                },
                {
                    label: 'UserTransactions',
                    routerLink: ['/user-transactions'],
                    permissions: permissions.TransactionsPermissions.ViewUserTransactions
                }
            ]
        },
        {
            label: 'Partners',
            icon: 'pi pi-fw icon-partners icon-size-sm',
            routerLink: ['/partners'],
            permissions: permissions.PartnerPermissions.ViewPartner
        },
        {
            label: 'Users',
            icon: 'pi pi-fw icon-users icon-size-sm',
            routerLink: ['/users'],
            permissions: permissions.AdminUserPermissions.ViewAdminUser
        },
        {
            label: 'Roles',
            icon: 'pi pi-fw icon-roles icon-size-sm',
            routerLink: ['/roles'],
            permissions: permissions.RolesPermissions.ViewRoles
        },
        {
            label: 'Logs',
            icon: 'pi pi-fw icon-list icon-size-sm', routerLink: ['/logs'],
            permissions: permissions.LogsPermissions.ViewLogsPermissions
        },
        {
            label: 'Reports',
            icon: 'pi pi-fw icon-reports icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'ByBets',
                    routerLink: ['/reports', 'by-bets'],
                    permissions: permissions.ReportsPermissions.ViewReportByBets
                },
                {
                    label: 'ByPlayers',
                    routerLink: ['/reports', 'by-players'],
                    permissions: permissions.ReportsPermissions.ViewReportByPlayersBets
                },
                {
                    label: 'ByAgents',
                    routerLink: ['/reports', 'by-agents'],
                    permissions: permissions.ReportsPermissions.ViewReportByAgents
                },
                {
                    label: 'ByProduct',
                    routerLink: ['/reports', 'by-product'],
                    permissions: permissions.ReportsPermissions.ViewReportByProductsBets
                },
                {
                    label: 'ByCategory',
                    routerLink: ['/reports', 'by-category'],
                    permissions: permissions.ReportsPermissions.ViewReportByCategoryBets
                },
                {
                    label: 'ByEarnings',
                    routerLink: ['/reports', 'by-earnings'],
                    permissions: permissions.ReportsPermissions.ViewReportByEarnings
                },
            ]
        },
        {
            label: 'CMS',
            icon: 'pi pi-fw icon-CMS icon-size-sm',
            level: 1,
            items: [
                {
                    label: 'Languages',
                    routerLink: ['/cms', 'languages'],
                    permissions: permissions.CMSPermissions.ViewLanguage
                },
                {
                    label: 'Translations',
                    routerLink: ['/cms', 'translations'],
                    permissions: permissions.CMSPermissions.ViewTranslations
                }
            ]
        }
    ],
};
